.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 6rem 4rem 6rem 4rem;
    color: var(--site_primaryM);
}

.cards{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
}
.title{
    color: var(--site_primaryM);
}

.card{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem 1rem;
    border-radius: 0.2rem;
    margin: 1rem;
    color: var(--site_textM);
    box-shadow: var(--shadow);
    overflow: hidden;
    transition: all 0.3s;
    background-color: var(--site_secondaryM);
}
.card:hover{
    color: var(--site_textCSe);
    background-color: var(--site_primaryM);
    transform: translateY(-0.5rem);
}

.icon-bg-card{
    position: absolute;
    font-size: 12rem;
    color: var(--site_primaryM);
    opacity: 0.05;
}

.icon-card{
    font-size: 3rem;
    color: white;
}

.title-card{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1rem;
    color: white;
}

.txt-card{
    text-align: center;
    font-size: 1.1rem;
    font-weight: 300;
    margin-top: 1rem;
    color: white;
}


@media (max-width: 768px) {
    .container{
        padding: 6rem 2rem 6rem 2rem;
    }

    .cards{
        display: flex;
        flex-direction: column;
    }
}